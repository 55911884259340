*{
  margin: 0;
  padding: 0;
  /* font-size: 20px; */
}

#root{
  background-color: #FAF1DC;
}

body {
  margin: 0;
  font-family: -apple-sys tem, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.aboutSummary {
  width: 80%;
  border: 2px solid #B09A69;
  border-radius: 15px; 
  padding: 20px; 
  background-color: #EBD096;
  
}

.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler .fa-bars {
  color: #fff;
  font-weight: bold;
}
/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler .fa-bars {
  color: #4f4f4f;
}
/* Color of the navbar AFTER scroll */
.navbar-scrolled {
  background-color: #fff;
  font-weight: bold;
}

.siteLogo > h1{
  color:#00a8f6;
}

/* HERO SECTION*/
.hero{
  width: 100%;
  /* background: rgb(12,150,53); */
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(179, 10, 10) 36%, rgb(0, 111, 246) 100%);
  box-shadow: inset 0 0 0 100vw rgba(255, 255, 255, 0.2);
  height: auto;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

/* PROPER SPACING OR PADDING BETWEEN SECTIONS */
.darkSection{
  padding: 60px 0;
  
  /* content: 30px; */
}

.flex-fod{
  display: flex;
  height: 400px;
  
}
/* Every Category has a green line afterwards */
.siteHeadings::before{
  content: "";
  display: block;
  width: 150px;
  position: relative;
  height: 4px;
  margin-right: 20px;
  background-color: #A68C9A;
}
.siteHeadings{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  padding: 20px 0;
  font-size: 40px;
  font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';

}
.siteHeadings::after{
  content: "";
  display: block;
  width: 150px;
  position: relative;
  height: 4px;
  margin-left: 20px;
  background-color: #A68C9A;
}

.contact{
  font-size: 18px;
  text-align: center;
}



@media screen and (max-width: 481px){
  .hero{
    height: auto;
    background: linear-gradient(135deg, rgb(167, 200, 113) 0%, rgb(10, 86, 179) 36%, rgb(0, 45, 246) 100%);
  }
  .heroContainer{
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  .heroWords{
    margin-top: 20px;
    text-align: center;
  }
  .heroRight > figure > img{
    width: 175px;
  }
  .about > img{
    width: 200px;
  }
}

@media screen and (min-width: 992px){
  .hero{
    padding: 100px 0;
  }
  .heroContainer{
    width: 70%;
  }
  .heroWords{
    margin: auto 0px;
  }
  .heroWords > h2{
    font-size: 45px;
  }
  .heroWords > p{
    font-size: 22px;
  }
  /* .projectCard > img {
    /* width: 45%; */
  /* }  */
  .toolsSection{
    padding: 150px 0;
  }
  .toolsGrid{
    margin: 30px 0;
  }
  .about > img{
    margin-bottom: 45px;
  }
  .aboutSummary{
    width: 60%;
    margin: auto;
    text-align: start;
  }
  .contactInfoBox > p {
    width: 40%;
  }
}



