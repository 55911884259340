#anthology{
    padding: 60px;
}
.alignAntho{
    text-align: center;
    font-style: italic;
}

#book {
    display: flex;
    justify-content: center;
}

#book img {
    display: block;
    width: 100%;
    max-width: 300px;
    transition: filter 0.3s ease;
}

#book img:hover {
    filter: brightness(1.1);
}


.anthologySummary {
    background-color: #FAF1DC;
    width: 90%; 
    max-width: 800px; 
    margin: 0 auto;
}

.book-caption-center{
    display:flex;
    justify-content: center;
    align-items: center;
}

#bot-margin{
    margin-bottom: 16px;
}

@media (min-width: 768px) {
    .anthologySummary {
        width: 80%;
    }

    #book img{
        max-width: 250px;
    }
}

@media (min-width: 1024px) {
    .anthologySummary {
        width: 70%;
    }

    #book img{
        max-width: 250px;
    }
}