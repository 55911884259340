.socialsContainer{
    text-align: center;
    margin: 30px 0 0;
}
.socialsContainer > img{
    margin: 10px;
    border-radius: 10px;
    border-style:solid 0px 3px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.socialsContainer > img:hover {
    background: #D0D0D0;
}