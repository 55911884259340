
.button {
    background-color: #ffffff; /* Green */
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
  }
  .button1 {
    width:150px;
    background-color: transparent;
    color: rgb(255, 255, 255);
    border: 2px solid #ffffff;
  }
  
  .button1:hover {
    background-color: #55495e;
    color: #ffffff;
    border: 2px solid #55495e;
  }
  .button2 {
    width:150px;
    background-color: white;
    color: rgb(0, 0, 0);
    border: 2px solid #ffffff;
  }
  
  .button2:hover {
    background-color: #55495e;
    color: #ffffff;
    border: 2px solid #55495e;
}
.flash{
    text-decoration:none;
    /* effect:none; */
    color:black;
}
.flash:hover{
    color:white;
}
  
.divider{
    width:20px;
    height:auto;
    display:inline-block;
}
.same{
    display:inline;
    display: inline-block;
    display: flex;
    /* align-items: center;  */
    /* justify-items: center; */
    justify-content: center;
}




.hero {
    background: url("../images/test1.png") no-repeat;
    

    filter: contrast(100%) brightness(100%) ; 
    background-size: cover;
    background-position: top center;
    padding: var(--section-padding) 0;
    height: 100vh;
    max-height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: -50px;
}
 /* https://alvarotrigo.com/blog/animated-backgrounds-css/ */
    @import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}
body{
    font-family: 'Exo', sans-serif;
}




.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
}
ul.circles{
    z-index: 1;
}
.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}
.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}
.circles li:nth-child(2){
    left: 10%;
    width: 50px;
    height: 50px;
    animation-delay: 1s;
    animation-duration: 7s;
}
.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}
.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}
.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}
.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}
.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}
.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}
.circles li:nth-child(11){
    left: 75%;
    width: 80px;
    height: 80px;
    animation-delay: 1s;
}
.circles li:nth-child(12){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 3s;
    animation-duration: 12s;
}
.circles li:nth-child(13){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 5s;
}
.circles li:nth-child(14){
    left: 37%;
    width: 60px;
    height: 60px;
    animation-delay: 7s;
    animation-duration: 18s;
}
.circles li:nth-child(15){
    left: 43%;
    width: 20px;
    height: 20px;
    animation-delay: 6s;
}
.circles li:nth-child(16){
    left: 45%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
.circles li:nth-child(17){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
.circles li:nth-child(18){
    left: 50%;
    width: 65px;
    height: 25px;
    animation-delay: 5s;
    animation-duration: 45s;
}
.circles li:nth-child(19){
    left: 20%;
    width: 55px;
    height: 45px;
    animation-delay: 4.5s;
    animation-duration: 35s;
}
.circles li:nth-child(20){
    left: 5%;
    width: 50px;
    height: 150px;
    animation-delay: 2.5s;
    animation-duration: 6s;
}
@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 100%;
    }
}
/* https://codepen.io/alvarotrigo/pen/ExvqdNa */
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
* {
padding: 0;
margin: 0;
}

span {
    /* font-size: 34px; */
    text-align: center;
    color: #ffffff;
    
}
#first{
    font-size: 30px;
    font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';
    
}
#second{
    font-size: 100px;
    font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';
    font-weight: bold;
}
span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
    display: inline;
}
p {
    display: inline;
}

span:nth-child(1) {
animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(2) {
animation: fade-in 2.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(3) {
animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(4) {
animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(5) {
animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(6) {
animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(7) {
animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(8) {
animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(9) {
animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(10) {
animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(11) {
animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(12) {
animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(13) {
animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(14) {
animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(15) {
animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(16) {
animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(17) {
animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(18) {
animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
    #second{
        font-size: 87px;
        font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';
        font-weight: bold;
    }
}


@media (max-width: 767px) {
    #first{
        font-size: 20px;
        font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';
        
    }
    #second{
        font-size: 58px;
        font-family: 'Calibri', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial';
        font-weight: bold;
    }

    .hero {
        background: url("../images/test1.png") no-repeat;
        
    
        filter: contrast(100%) brightness(100%) ; 
        background-size: cover;
        background-position: top center;
        padding: var(--section-padding) 0;
        height: 85vh;
        max-height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        top: -50px;
    }
}